import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth, firestore } from '../firebase/firebase';
import { doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from '@firebase/auth';
import * as ROLES from '../constants/roles';

const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [refreshUser, setRefreshUser] = useState(false);
  const [authAlert, setAuthAlert] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState('');

  useEffect(() => {
    setAuthLoading(true);
    const unsubscribeAuth = onAuthStateChanged(auth, async (res) => {
      if (res) {
        let userDoc = doc(firestore, 'users', res.uid);
        //let userDoc = doc(firestore, 'users', 'NehkwUUhA9WT1z4AvxkjqvTxGkd2');

        let user = await getDoc(userDoc);

        if (user.exists()) {
          setUser({
            id: res.uid,
            ...user.data(),
          });
        }
      } else {
        setUser(null);
      }
      //We will want to grab the data from the profile and set the profile

      setAuthError('');
      setAuthLoading(false);
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    const refreshTheUser = async () => {
      if (refreshUser === true) {
        //console.log("we are refreshing the user");
        let userDoc = doc(firestore, 'users', user.id);
        let newuser = await getDoc(userDoc);
        if (newuser.exists()) {
          setUser({
            id: newuser.id,
            ...newuser.data(),
          });
        }
      } else {
        setUser(null);
      }
      setRefreshUser(false);
    };
    return () => {
      refreshTheUser();
    };
  }, [refreshUser]);

  const registerUser = async ({ email, firstName, lastName, password }) => {
    //
    //console.log('this is my user info that I am getting  -->', email, firstName, lastName, password);
    setAuthLoading(true);
    try {
      let user = await createUserWithEmailAndPassword(auth, email, password);
      //We will want to then create their profile in firestore

      if (user.user) {
        await setDoc(doc(firestore, 'users', user.user.uid), {
          firstName: firstName,
          lastName: lastName,
          fullName: firstName + ' ' + lastName,
          initials: firstName[0] + lastName[0],
          email: email,
          defaultPassword: true,
          role: ROLES.SUPERADMIN,
          userAdded: serverTimestamp(),
        });
      }

      //Then update the user info
    } catch (error) {
      console.log('this is my error --->', error);
      setAuthError(error);
    } finally {
      setAuthLoading(false);
    }
  };

  const signInUser = async ({ email, password }) => {
    setAuthLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        //
      })
      .catch((error) => {
        console.log('this is my error', error);
        if (error.message) {
          //console.log('this is my errror.message -->< ', error.message);
          setAuthError(error.message);
          setAuthAlert(true);
        }
      })
      .finally(setAuthLoading(false));
    //We will want to load the profile information as well but that might get done in the useEffect
  };

  const logoutUser = () => {
    ///
    //console.log('did we sign out???');
    signOut(auth);
  };

  const forgotPassword = ({ email }) => {
    //
    return sendPasswordResetEmail(auth, email);
  };

  const closeAuthAlert = () => {
    setAuthAlert(false);
    setAuthError('');
  };

  const contextValue = {
    user,
    authLoading,
    authAlert,
    authError,
    setRefreshUser,
    registerUser,
    signInUser,
    logoutUser,
    forgotPassword,
    closeAuthAlert,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
