import { StyleSheet } from '@react-pdf/renderer';

// Create styles
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 0,
    margin: 0,
    paddingBottom: 10,
  },
  logo: {
    flexDirection: 'row', // Display items in a row (horizontal)
    paddingTop: 17,
    marginRight: 30,
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignContent: 'center',
    width: '33%',
  },
  image: {
    display: 'block',
    maxHeight: 33,
    maxWidth: 70,
    marginRight: 10, // Add some margin between images
  },
  tierLogoImg: {
    //display: 'block',
    height: 30,
    width: 30,
    padding: 1,
  },
  header: {
    paddingBottom: 15,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    //flexGrow: 1
  },
  headerBar: {
    width: '100%',
    height: 11,
    display: 'block',
  },
  headerText: {
    textAlign: 'left',
    fontFamily: 'RobotoCondensed',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    fontSize: 22,
  },
  headerTextholder: {
    textAlign: 'left',
    width: '66%',
    paddingTop: 28,
    //paddingRight: 50,
    paddingLeft: 32,
    //paddingBottom: 13,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  clinicInfoBlock: {
    backgroundColor: '#FFFFFF',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 35,
  },
  clinicInfoTopRow: {
    flexDirection: 'row',
    width: '90%',
    marginBottom: 5,
  },
  clinicInfo: {
    fontFamily: 'RobotoCondensed',
    fontSize: 24,
    fontWeight: 300,
  },
  tierInfo: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 21,
    paddingLeft: 13,
  },
  sectionOne: {
    width: '33%',
    display: 'block',
  },
  sectionTwo: {
    width: '46%',
    display: 'block',
  },
  priceText: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 24,
    color: '#707070',
    marginLeft: 38,
  },
  sectionThree: {
    width: '21%',
    display: 'block',
  },
  headline: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#707070',
    fontSize: 13,
    marginBottom: 5,
    textTransform: 'uppercase',
  },
  treatmentsTableSection: {
    paddingLeft: 32,
    paddingRight: 31,
    paddingBottom: 36,
  },
  treatTitle: {
    color: '#5A9BAB',
    borderBottom: 2,
    borderBottomColor: '#5A9BAB',
    fontFamily: 'RobotoCondensed',
    fontWeight: 'normal',
    fontSize: 13,
  },
  profitTableTopSection: {
    color: '#707070',
    flexDirection: 'row',
    fontSize: 9,
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    paddingTop: 17,
    paddingBottom: 6,
  },
  tableheaderBar: {
    width: '100%',
    height: 2,
    display: 'block',
  },
  profitTableTopTreatment: {
    textAlign: 'left',
    width: '25%',
    //paddingRight: 130,
    //paddingTop: 10,

    display: 'block',
  },
  profitTableTopTreatPerMonth: {
    textAlign: 'center',
    //width: 50,
    width: '14%',
  },
  profitTableTopPatientPrice: {
    textAlign: 'center',
    //width: 70,
    width: '17%',
  },
  profitTableTopConsumable: {
    textAlign: 'center',
    //width: 52,
    width: '14%',
  },
  profitTableTopOther: {
    textAlign: 'center',
    //width:36,
    width: '10%',
  },
  profitTableTopWeekProfit: {
    textAlign: 'right',
    //width: 28,
    width: '18%',
  },
  ///////////////////////////////
  rowBottom: {
    width: '100%',
    height: 2,
    display: 'block',
    marginTop: 8,
  },
  profitRowTreatment: {
    textAlign: 'left',
    width: '25%',
    //paddingRight: 130,
  },
  profitRowTreatPerMonth: {
    textAlign: 'center',
    //width: 50,
    width: '14%',
    //paddingRight: 23,
  },
  profitRowPatientPrice: {
    textAlign: 'center',
    //width: 70,
    width: '17%',
    //paddingRight: 23,
  },
  profitRowConsumable: {
    textAlign: 'center',
    //width: 52,
    width: '14%',
    //paddingRight: 23,
  },
  profitRowOther: {
    textAlign: 'center',
    //width:36,
    width: '10%',
    //paddingRight: 30,
  },
  profitRowWeekProfit: {
    textAlign: 'right',
    //width: 28,
    width: '18%',
    //paddingRight: 16,
  },
  profitRow: {
    flexDirection: 'row',
    paddingTop: 10,
    '&:nth-of-type(even)': {
      backgroundColor: '#707070',
    },
  },
  itemDescription: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 13,
    color: '#707070',
  },
  totalsSection: {
    width: '100%',
    backgroundColor: '#5A9BAB',
    paddingTop: 28,
    paddingBottom: 28,
  },
  p2TotalsSection: {
    width: '100%',
    backgroundColor: '#5A9BAB',
    paddingTop: 32,
    paddingBottom: 32,
  },
  totalsRow: {
    flexDirection: 'row',
  },
  totalsOne: {
    width: '33%',
    display: 'block',
    paddingLeft: 32,
  },
  p2totalsOne: {
    width: '33%',
    display: 'block',
    paddingLeft: 25,
  },
  totalsTwo: {
    width: '33%',
    display: 'block',
  },
  totalsThree: {
    width: '33%',
    display: 'block',
  },
  totalsTitleOne: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    textAlign: 'left',
    //paddingRight: 140,
    marginBottom: 5,
  },
  totalsTitleOneSecond: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    textAlign: 'left',
    marginBottom: 5,
  },
  totalsTitleTwo: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    textAlign: 'left',
    marginBottom: 5,
  },
  totalsTitleThree: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    textAlign: 'left',
    paddingRight: 54,
    marginBottom: 5,
  },
  totalsTotals: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 32,
    color: '#FFFFFF',
    textAlign: 'left',
  },
  footerSection: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 0,
    paddingBottom: 3,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  fullFooter: {
    width: '100%',
    flexDirection: 'row',
    paddingBottom: 5,
  },
  footerText: {
    color: '#707070',
    fontFamily: 'RobotoCondensed',
    fontWeight: 'light',
    fontSize: 7,
    textAlign: 'left',
  },
  footLeft: {
    marginRight: 21,
    width: '57%',
  },
  footRight: {
    width: '33%',
  },
  page2header: {
    paddingTop: 13,
  },
  p2LogoHolder: {
    marginBottom: 8,
  },
  page2subtitle: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    textAlign: 'left',
    color: '#707070',
    textTransform: 'uppercase',
  },

  page2Graph: {
    paddingBottom: 40,
    paddingLeft: 30,
    paddingRight: 30,
  },

  secondGraph: {
    marginBottom: 40,
    marginTop: 60,
  },
  page2subBar: {
    paddingTop: 25,
    flexDirection: 'row',
    borderBottom: 2,
    borderBottomColor: '#5A9BAB',
    alignItems: 'center',
    alignContent: 'center',
  },
  weekly: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    textAlign: 'left',
    color: '#707070',
    //borderBottom: 13,
    //borderBottomColor: '#5A9BAB',
  },
  page2subTier: {
    flexDirection: 'row',
    textAlign: 'right',
    justifyContent: 'flex-end',
    width: '95%',
  },
  graphBlock: {
    height: 275,
  },
  breakWeeksHolder: {
    position: 'absolute',
    flexDirection: 'row',
    top: 20,
  },
  tierBreak: {
    flexDirection: 'column',
    marginRight: 40,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  currentTierWeeks: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 28,
    textAlign: 'center',
    color: '#707070',
  },
  breaktext: {
    width: 76,
    fontSize: 12,
    fontFamily: 'RobotoCondensed',
    color: '#707070',
    textTransform: 'uppercase',
  },
  breaktextDifference: {
    width: 125,
    fontSize: 12,
    fontFamily: 'RobotoCondensed',
    color: '#707070',
    textAlign: 'center',
  },
  breakTier: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  graphHolder: {
    width: '100%',
  },
  tableDataContainer: {
    flexDirection: 'column',
    paddingLeft: 32,
    paddingRight: 32,
    marginBottom: 13,
  },
  p3dataTable: {
    flexDirection: 'column',
  },
  p3Tablerow: {
    flexDirection: 'row',
    display: 'block',
    paddingTop: 8,
    paddingBottom: 8,
  },
  evenRow: {
    backgroundColor: 'rgba(112, 112, 112, 0.1)',
  },
  highlight: {
    backgroundColor: '#5A9BAB',
  },
  p3dataHighlight: {
    fontSize: 12,
    fontFamily: 'RobotoCondensed',
    backgroundColor: '#5A9BAB',
    color: '#FFFFFF',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  p3data: {
    fontSize: 12,
    fontFamily: 'RobotoCondensed',
    color: '#707070',
    textAlign: 'center',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    '&:last-child': {
      paddingRight: 8,
    },
  },

  titleBlock: {
    flexDirection: 'row',
    borderBottom: 2,
    borderBottomColor: '#5A9BAB',
  },
  p3Tabletitle: {
    fontSize: 13,
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#707070',
    marginBottom: 13,
    marginTop: 13,
  },
  tableTreats: {
    width: 70,
    marginRight: 27,
  },
  tableTipCost: {
    width: 51,
    marginRight: 38,
  },
  tableLoan: {
    width: 77,
    marginRight: 45,
  },
  tableSales: {
    width: 41,
    marginRight: 49,
  },
  tableProfit: {
    width: 41,
    marginRight: 27,
  },
  tableBreakEven: {
    width: 61,
  },
  p3TitleGrid: {
    fontFamily: 'RobotoCondensed',
    fontSize: 12,
    color: '#5A9BAB',
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingBottom: 8,
  },
  p3SalesRep: {
    color: '#FFFFFF',
    flexDirection: 'column',
    paddingLeft: 31,
    width: '69%',
  },
  repheader: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  repName: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 18,
    textTransform: 'uppercase',
  },
  repTitle: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 14,
    marginBottom: 12,
  },
  repEmail: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 11,
  },
  repPhone: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 11,
  },
  p3companySection: {
    color: '#FFFFFF',
    flexDirection: 'column',
  },
  p3SoltaLogo: {
    width: 127,
    height: 54,
    marginBottom: 10,
  },
  p3CompanyAddress: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 11,
  },
  horizontal: {
    flexDirection: 'row', // Display items in a row (horizontal)
    alignContent: 'center',
    alignItems: 'center',
  },
  verticle: {
    flexDirection: 'column',
  },
});
