export const LANDING = '/';
export const LOGIN = '/login';
export const ADMIN = '/admin';
export const SIGN_UP = '/signup';
export const SIGN_OUT = '/signout';
export const PASSWORD_FORGET = '/password-reset';
export const USERS = '/users/:id';
export const USER = '/users/';
export const CLINICS = '/c/:id';
export const TERMS = '/terms';
export const CLINICADMIN = '/clinic-admin/:id';
export const TREATMENTS = '/treatments';
export const ADMINCLINIC = '/admin-clinic';
export const ROI_PAGE = '/roi/:id';
export const ROI = '/roi/';
