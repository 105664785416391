import React, { createContext, useState, useContext, useEffect } from 'react';
import { firestore } from '../firebase/firebase';
import { getDocs, collection, orderBy, query, where } from 'firebase/firestore';

const TierContext = createContext({});

export const TierContextProvider = ({ children }) => {
  const [tiers, setTiers] = useState([]);
  const [tierAlert, setTierAlert] = useState(false);
  const [tiersLoading, setTiersLoading] = useState(true);
  const [tierError, setTierError] = useState('');

  useEffect(() => {
    const fetchTiers = async () => {
      // You can await here

      let tiers = [];

      const tierSnapshot = await getDocs(query(collection(firestore, 'tiers'), orderBy('purchaseCommitment', 'asc'), where('promoOrderProgram', '==', 'cMirhn7ydWPyPq0OGiuK')));
      //console.log('this is my tierSnap shot ---> ', tierSnapshot);
      tierSnapshot.forEach((doc) => {
        tiers.push({ id: doc.id, ...doc.data() });
      });

      setTiers([...tiers]);
      setTiersLoading(false);
    };

    fetchTiers().catch((err) => {
      if (!tiersLoading) return;
      console.error('Failed to fetch Tiers --->', err);
      setTierError(err);
      setTierAlert(true);
    });

    return () => {
      setTiersLoading(false);
    };
    //Get all of the tiers that we need from the firebase
  }, [tiersLoading]);

  const contextValue = {
    tiers,
    tierAlert,
    tiersLoading,
    tierError,
    setTierError,
    setTierAlert,
  };

  return <TierContext.Provider value={contextValue}>{children}</TierContext.Provider>;
};

export const useTierContext = () => useContext(TierContext);
