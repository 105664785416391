import React, { createContext, useState, useContext, useEffect } from 'react';
import { firestore } from '../firebase/firebase';
import { getDocs, collection, orderBy, query, where } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useUserContext } from './userContext';
const SystemContext = createContext({});

export const SystemContextProvider = ({ children }) => {
  const [systems, setSystems] = useState([]);
  const [systemAlert, setSystemAlert] = useState(false);
  const [systemsLoading, setSystemsLoading] = useState(true);
  const [systemError, setSystemError] = useState('');
  const [systemsSelected, setSystemsSelected] = useState([]);
  const { user } = useUserContext();
  //console.log('this is my systems selected ---> ', systemsSelected);

  useEffect(() => {
    async function getLogoURL(logo) {
      try {
        const storage = getStorage();
        const url = await getDownloadURL(ref(storage, logo));

        return url;
      } catch (error) {
        return error;
      }
    }

    const fetchSystems = async () => {
      // You can await here

      //let systems = [];

      const systemSnapshot = await getDocs(query(collection(firestore, 'technologies'), orderBy('order', 'asc'), where('roi', '==', true)));
      const systemPromises = systemSnapshot.docs.map(async (doc) => {
        //I really want to get the logos here
        const logoURL = doc.data().logo ? await getLogoURL(doc.data().logo) : '';

        return { id: doc.id, logoURL, ...doc.data() };
      });

      const systems = await Promise.all(systemPromises);

      setSystems([...systems]);
      setSystemsLoading(false);
    };

    if (user) {
      fetchSystems().catch((err) => {
        if (!systemsLoading) return;
        console.error('Failed to fetch Systems --->', err);
        setSystemError(err);
        setSystemAlert(true);
      });
    }

    return () => {
      //setSystemsLoading(false);
    };
    //Get all of the systems that we need from the firebase
  }, [systemsLoading, user]);

  /////////////////////////////////////////////
  //
  // SystemAddedToROI
  // >> This function add the system to trigger
  //    the tips to be loaded in the tip context
  //
  /////////////////////////////////////////////

  const systemAddedToROI = (id) => {
    const mySystems = systemsSelected;

    const selectedSystem = systems.filter((sys) => sys.id === id);

    if (selectedSystem.length > 0) {
      if (!mySystems.some((sys) => sys.id === selectedSystem[0].id)) {
        mySystems.push({ ...selectedSystem[0] });
        setSystemsSelected([...mySystems]);
      }
    }
  };

  const contextValue = {
    systems,
    systemAlert,
    systemsLoading,
    systemError,
    systemsSelected,
    setSystemsSelected,
    setSystemError,
    setSystemAlert,
    systemAddedToROI,
  };

  return <SystemContext.Provider value={contextValue}>{children}</SystemContext.Provider>;
};

export const useSystemContext = () => useContext(SystemContext);
