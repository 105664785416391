import { Image } from '@react-pdf/renderer';
import BronzeLogo from '../../ROI/images/bronze.png';
import SilverLogo from '../../ROI/images/silver.png';
import GoldLogo from '../../ROI/images/gold.png';
import PlatinumLogo from '../../ROI/images/platinum.png';
import RubyLogo from '../../ROI/images/ruby.png';
import DiamondLogo from '../../ROI/images/diamond.png';
import EmeraldLogo from '../../ROI/images/emerald-badge@2x.png';
import { styles } from '../styles/pdfStyles';

export const tierLogo = (id) => {
  switch (id) {
    case '"EnHRIMZGhhJZeTCUL6NX"':
      return <Image src={BronzeLogo} alt='Bronze' style={styles.tierLogoImg} />;
    case '3IaLvKVToLsbcC8neru7':
      return <Image src={SilverLogo} alt='Silver' style={styles.tierLogoImg} />;
    case 'hfgJtLRuei3Ac42Gu1tj':
      return <Image src={GoldLogo} alt='Gold' style={styles.tierLogoImg} />;
    case 'TktE8KkUfRptydPNLOk1':
      return <Image src={PlatinumLogo} alt='Platinum' style={styles.tierLogoImg} />;
    case 'eEH4ihpXS9hqi1Bd3Q93':
      return <Image src={EmeraldLogo} alt='Emerald' style={styles.tierLogoImg} />;
    case '3vYBprZ763vNRowuvVRI':
      return <Image src={RubyLogo} alt='Ruby' style={styles.tierLogoImg} />;
    case '7FiOjjal0x2LkVmv9gbv':
      return <Image src={DiamondLogo} alt='Diamond' style={styles.tierLogoImg} />;
    default:
      return <Image src={BronzeLogo} alt='Bronze' style={styles.tierLogoImg} />;
  }
};
