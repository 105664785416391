import React, { createContext, useState, useContext, useEffect } from 'react';
import { firestore } from '../firebase/firebase';
import { getDocs, collection, where, query, orderBy } from 'firebase/firestore';
import { useSystemContext } from './systemContext';

const TipContext = createContext({});

export const TipContextProvider = ({ children }) => {
  const [tips, setTips] = useState([]);
  const [tipAlert, setTipAlert] = useState(false);
  const [tipsLoading, setTipsLoading] = useState(true);
  const [tipError, setTipError] = useState('');
  const [systemCount, setSystemCount] = useState(0);
  const { systemsSelected } = useSystemContext();

  ///This will load the tips on load
  // We will want to actually load the tips if a brand is selected

  useEffect(() => {
    const fetchTips = async () => {
      try {
        let myTips = [];
        //We will want to get all of the tips that are for each systems
        await Promise.all(
          systemsSelected.map(async (system) => {
            const tipSnapshot = await getDocs(query(collection(firestore, 'items'), where('brandID', '==', system.brand), where('itemSupply', '==', false), where('roi', '==', true), orderBy('listOrder', 'asc')));

            return tipSnapshot.forEach((doc) => {
              //console.log('this is my doc data --->', doc.id, doc.data());

              myTips.push({ id: doc.id, ...doc.data() });
              //console.log("im map -> ", doc.data());
            });
          })
        );
        //console.log("Tips -> ", myTips);
        setTips([...myTips]);
        setSystemCount(systemsSelected.length);
        setTipsLoading(false);
      } catch (error) {
        // Handle errors here
        console.error(error);
      }
    };

    //If we have a system selected then we need to load tips
    if (systemsSelected.length > 0 && tipsLoading) {
      fetchTips();
    }

    //If we have more systems added we need to reload the tips
    if (systemCount < systemsSelected.length) {
      setTipsLoading(true);
    }

    return () => {
      //setTipsLoading(false);
    };
    //Get all of the brands that we need from the firebase
  }, [tipsLoading, systemsSelected, tips, systemCount]);

  const contextValue = {
    tips,
    tipAlert,
    tipsLoading,
    tipError,
    setTipError,
    setTipAlert,
  };

  return <TipContext.Provider value={contextValue}>{children}</TipContext.Provider>;
};

export const useTipContext = () => useContext(TipContext);
