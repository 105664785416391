import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import ErrorBoundary from './components/Errors';
import Alerts from './components/Alerts';
//import SystemAlerts from '../Alerts';
import CssBaseline from '@mui/material/CssBaseline';
//import Footer from './components/Footer';

const Dashboard = lazy(() => import('./components/Dashboard'));
const ROI = lazy(() => import('./components/ROI'));
const LoginPage = lazy(() => import('./components/SignIn'));
const LoadingBlock = lazy(() => import('./components/Loading'));
const ForgotPassword = lazy(() => import('./components/SignIn/forgotPassword'));

function App() {
  return (
    <div className='app'>
      <CssBaseline />
      <Router>
        <div className='app-page-content'>
          <Suspense fallback={<LoadingBlock />}>
            <ErrorBoundary>
              <Routes>
                <Route exact path={ROUTES.LANDING} element={<Dashboard />} />
                <Route exact path={ROUTES.ROI_PAGE} element={<ROI />} />
                <Route exact path={ROUTES.LOGIN} element={<LoginPage />} />
                <Route exact path={ROUTES.PASSWORD_FORGET} element={<ForgotPassword />} />
              </Routes>
            </ErrorBoundary>
          </Suspense>

          <Alerts />
        </div>
      </Router>
    </div>
  );
}

export default App;
