import React from 'react';
import LoadingGraphic from './logoLodingBlock';

import Grid from '@mui/material/Grid';

export default function LoadingBlock() {
  return (
    <Grid item xs={12} container spacing={2} justifyContent='center'>
      <Grid item xs={2} align='center'>
        <LoadingGraphic />
      </Grid>
    </Grid>
  );
}
