export function convertToRoundedNumber(number) {
  if (isNaN(number)) {
    return 0;
  }

  if (typeof number === 'number') {
    return Math.ceil(number * 100) / 100;
  } else {
    //It is a number no need to conver
    return number;
  }
}
