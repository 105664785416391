/*****************************************************************************/
/*  Date:      Oct 11 2021                                                   */
/*  Author:    Ben Wakutz                                                    */
/*  Purpose:   grabs the tierlogo for the ROI Remake                         */
/*                                                                           */
/*****************************************************************************/

import * as COLOURS from '../../../constants/colours';

const TierColor = (tier) => {
  //console.log("IN GET TIERLOGO ->",tier);
  //console.log('these are my props', this.props);

  if (tier) {
    switch (tier) {
      case 'List':
        return COLOURS.list;
      case 'Bronze':
        return COLOURS.bronze;
      case 'Silver':
        return COLOURS.silver;
      case 'Argent':
        return COLOURS.silver;
      case 'Gold':
        return COLOURS.gold;
      case 'Or':
        return COLOURS.gold;
      case 'Platinum':
        return COLOURS.platinum;
      case 'Platine':
        return COLOURS.platinum;
      case 'Emerald':
        return COLOURS.emerald;
      case 'Ruby':
        return COLOURS.ruby;
      case 'Rubis':
        return COLOURS.ruby;
      case 'Diamond':
        return COLOURS.diamond;
      case 'Diamant':
        return COLOURS.diamond;
      case 'Current Profits':
        return COLOURS.silver;
      case 'Bénéfices Actuels':
        return COLOURS.silver;
      case 'Potential Profits':
        return COLOURS.list;
      case 'Bénéfices Potentiels':
        return COLOURS.list;
      default:
        return COLOURS.list;
    }
  }
};

export default TierColor;
