export function convertToCurrency(number) {
  const parsedNumber = parseFloat(number);

  if (isNaN(parsedNumber)) {
    return 0;
  }

  const value = Math.ceil(number);

  return value.toLocaleString('en-US');
}

export function convertToCurrencyChart(number) {
  const value = Math.ceil(number);

  if (isNaN(value)) {
    return `$0`;
  }

  return `$${value.toLocaleString('en-US')}`;
}
