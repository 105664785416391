export const weeksPerYear = 48;
export const weeksPerMonth = 4;
export const monthsPerYear = 12;

//Returns all values to a weekly calculation
export const weeklyAnnual = 48; //48 weeks in a year
export const weeklyQuarterly = 12; //12 weeks in a quarter of a year
export const weeklyMonthly = 4; //4 Weeks in a month
export const weekly = 1; //1 Week

//Returns all values to a monthly calculation
export const monthlyAnnual = 12; //12 months in a year
export const monthlyQuarterly = 3; // 3 Months in a quarter
export const monthly = 1; //1 Month

//Returns all values to a quarterly calculation
export const quarterlyAnnual = 4; //4 quarters in a year
export const quarterly = 1; // 1 Quarter

//Returns all values to a quarterly calculation
export const annualy = 1; //1 Year
