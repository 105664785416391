/*****************************************************************************/
/*  Date:      Nov 15 2023                                                   */
/*  Modified:                                                                */
/*  Author:    Ben Wakutz                                                    */
/*  Purpose:   Creates a pdf for the ROIv2 Solta US                          */
/*                                                                           */
/*****************************************************************************/

import React from 'react';
import { Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { Page, Text, View, Document, StyleSheet, Image, Font, Svg } from '@react-pdf/renderer';
import { BarChart, Bar, XAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { convertToCurrencyChart } from '../../functions/convertCurrency';
import { useROIContext } from '../../context/roiContext';
import { convertToRoundedNumber } from '../../functions/roundNumberUp';
import { convertToCurrency } from '../../functions/convertCurrency';
//import TierLogo from './functions/getTierIcon';
import moment from 'moment';
//import * as MATH from '../../constants/math';
//import * as MODE from '../../constants/modes';
import * as TIMERANGE from '../../constants/timeRanges';
import RobotoBold from '../../fonts/Roboto/Roboto-Bold.ttf';
import RobotoLight from '../../fonts/Roboto/Roboto-Light.ttf';
import RobotoCondensed from '../../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf';
import RobotoCondensedBold from '../../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf';
import RobotoCondensedLight from '../../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf';
import BronzeLogo from '../ROI/images/bronze.png';
import SilverLogo from '../ROI/images/silver.png';
import GoldLogo from '../ROI/images/gold.png';
import PlatinumLogo from '../ROI/images/platinum.png';
import RubyLogo from '../ROI/images/ruby.png';
import DiamondLogo from '../ROI/images/diamond.png';
import EmeraldLogo from '../ROI/images/emerald-badge@2x.png';
import SoltaLogo from '../ROI/images/solta-logo_white.png';
import TierColor from './functions/getTierColor';

import ReactPDFChart from 'react-pdf-charts';
//import Chart from './Chart';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 0,
    margin: 0,
    paddingBottom: 10,
  },
  logo: {
    flexDirection: 'row', // Display items in a row (horizontal)
    paddingTop: 13,
    marginRight: 30,
    marginLeft: 10,
  },
  image: {
    display: 'block',
    maxHeight: 33,
    maxWidth: 70,
    marginRight: 10, // Add some margin between images
  },
  tierLogoImg: {
    //display: 'block',
    height: 30,
    width: 30,
    padding: 1,
  },
  header: {
    paddingBottom: 13,
    flexDirection: 'row',
    //flexGrow: 1
  },
  systemPriceHolder: {
    flexDirection: 'row',
    paddingBottom: 10,
  },
  headerBar: {
    width: '100%',
    height: 11,
    display: 'block',
  },
  headerText: {
    textAlign: 'left',
    fontFamily: 'RobotoCondensed',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    fontSize: 22,
  },
  headerTextholder: {
    textAlign: 'left',
    width: 348,
    paddingTop: 17,
    //paddingRight: 50,
    paddingLeft: 32,
    paddingBottom: 13,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  clinicInfoBlock: {
    backgroundColor: '#FFFFFF',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 20,
  },
  clinicInfoTopRow: {
    flexDirection: 'row',
    width: '90%',
    marginBottom: 5,
  },
  clinicInfo: {
    fontFamily: 'RobotoCondensed',
    fontSize: 24,
    fontWeight: 300,
  },
  tierInfo: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 21,
    paddingLeft: 13,
  },
  sectionOne: {
    width: '25%',
    display: 'block',
  },
  sectionTwo: {
    width: '20%',
    display: 'block',
  },
  sectionTwo2: {
    width: '34%',
    display: 'block',
  },
  priceText: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 24,
    color: '#707070',
    /*  marginLeft: 5, */
  },
  sectionThree: {
    width: '21%',
    display: 'block',
  },
  headline: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#707070',
    fontSize: 13,
    marginBottom: 5,
    textTransform: 'uppercase',
  },
  treatmentsTableSection: {
    paddingLeft: 32,
    paddingRight: 31,
    paddingBottom: 36,
  },
  treatTitle: {
    color: '#5A9BAB',
    borderBottom: 2,
    borderBottomColor: '#5A9BAB',
    fontFamily: 'RobotoCondensed',
    fontWeight: 'normal',
    fontSize: 13,
  },
  profitTableTopSection: {
    color: '#707070',
    flexDirection: 'row',
    fontSize: 9,
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    paddingTop: 17,
    paddingBottom: 6,
  },
  tableheaderBar: {
    width: '100%',
    height: 2,
    display: 'block',
  },
  profitTableTopTreatment: {
    textAlign: 'left',
    width: '35%',
    paddingRight: 130,
    paddingTop: 10,
  },
  profitTableTopTreatPerMonth: {
    textAlign: 'center',
    //width: 50,
    width: '14%',
    paddingRight: 23,
  },
  profitTableTopPatientPrice: {
    textAlign: 'center',
    //width: 70,
    width: '17%',
    paddingRight: 23,
  },
  profitTableTopConsumable: {
    textAlign: 'center',
    //width: 52,
    width: '14%',
    paddingRight: 23,
  },
  profitTableTopOther: {
    textAlign: 'center',
    //width:36,
    width: '12%',
    paddingRight: 30,
  },
  profitTableTopWeekProfit: {
    textAlign: 'center',
    //width: 28,
    width: '8%',
    paddingRight: 16,
  },
  ///////////////////////////////
  rowBottom: {
    width: '100%',
    height: 2,
    display: 'block',
    marginTop: 8,
  },
  profitRowTreatment: {
    textAlign: 'left',
    width: '33%',
    //paddingRight: 130,
  },
  profitRowTreatPerMonth: {
    textAlign: 'center',
    //width: 50,
    width: '14%',
    //paddingRight: 23,
  },
  profitRowPatientPrice: {
    textAlign: 'center',
    //width: 70,
    width: '17%',
    //paddingRight: 23,
  },
  profitRowConsumable: {
    textAlign: 'center',
    //width: 52,
    width: '14%',
    //paddingRight: 23,
  },
  profitRowOther: {
    textAlign: 'center',
    //width:36,
    width: '10%',
    //paddingRight: 30,
  },
  profitRowWeekProfit: {
    textAlign: 'right',
    //width: 28,
    width: '10%',
    //paddingRight: 16,
  },
  profitRow: {
    flexDirection: 'row',
    paddingTop: 10,
    '&:nth-of-type(even)': {
      backgroundColor: '#707070',
    },
  },
  itemDescription: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 14,
    color: '#707070',
  },
  totalsSection: {
    width: '100%',
    backgroundColor: '#5A9BAB',
    paddingTop: 32,
    paddingBottom: 44,
    position: 'absolute',
    bottom: 72,
  },
  p2TotalsSection: {
    width: '100%',
    backgroundColor: '#5A9BAB',
    paddingTop: 32,
    paddingBottom: 32,
    position: 'absolute',
    bottom: 72,
  },
  totalsRow: {
    flexDirection: 'row',
  },
  totalsOne: {
    width: '42%',
    display: 'block',
    paddingLeft: 32,
  },
  p2totalsOne: {
    width: '45%',
    display: 'block',
    paddingLeft: 25,
  },
  totalsTwo: {
    width: '35%',
    display: 'block',
  },
  totalsThree: {
    width: '23%',
    display: 'block',
  },
  totalsTitleOne: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    textAlign: 'left',
    paddingRight: 140,
    marginBottom: 5,
  },
  totalsTitleOneSecond: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    textAlign: 'left',
    paddingRight: 68,
    marginBottom: 5,
  },
  totalsTitleTwo: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    textAlign: 'left',
    paddingRight: 93,
    marginBottom: 5,
  },
  totalsTitleThree: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    textAlign: 'left',
    paddingRight: 54,
    marginBottom: 5,
  },
  totalsTotals: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 32,
    color: '#FFFFFF',
    textAlign: 'left',
  },
  footerSection: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 0,
    paddingBottom: 3,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  fullFooter: {
    width: '100%',
    flexDirection: 'row',
    paddingBottom: 5,
  },
  footerText: {
    color: '#707070',
    fontFamily: 'RobotoCondensed',
    fontWeight: 'light',
    fontSize: 7,
    textAlign: 'left',
  },
  footLeft: {
    marginRight: 21,
    width: '57%',
  },
  footRight: {
    width: '33%',
  },
  page2header: {
    paddingTop: 13,
  },
  page2subtitle: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    textAlign: 'left',
    color: '#707070',
    textTransform: 'uppercase',
  },
  page2Graph: {
    paddingBottom: 106,
    paddingLeft: 30,
    paddingRight: 30,
  },
  page2subBar: {
    paddingTop: 25,
    flexDirection: 'row',
    borderBottom: 2,
    borderBottomColor: '#5A9BAB',
  },
  weekly: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 12,
    textAlign: 'left',
    color: '#707070',
    borderBottom: 22,
    borderBottomColor: '#5A9BAB',
  },
  page2subTier: {
    flexDirection: 'row',
    textAlign: 'right',
    justifyContent: 'flex-end',
    width: '95%',
  },
  p2LogoHolder: {
    marginBottom: 5,
  },
  graphBlock: {
    height: 275,
  },
  breakWeeksHolder: {
    position: 'absolute',
    flexDirection: 'row',
    top: 20,
  },
  tierBreak: {
    flexDirection: 'column',
    marginRight: 40,
  },
  currentTierWeeks: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 28,
    textAlign: 'center',
  },
  breaktext: {
    width: 76,
    fontSize: 12,
    fontFamily: 'RobotoCondensed',
    color: '#707070',
    textTransform: 'uppercase',
  },
  breakTier: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  graphHolder: {},
  tableDataContainer: {
    flexDirection: 'column',
    paddingLeft: 32,
    paddingRight: 32,
    marginBottom: 13,
  },
  p3dataTable: {
    flexDirection: 'column',
  },
  p3Tablerow: {
    flexDirection: 'row',
    display: 'block',
    paddingTop: 8,
    paddingBottom: 8,
  },
  evenRow: {
    backgroundColor: 'rgba(112, 112, 112, 0.1)',
  },
  highlight: {
    backgroundColor: '#5A9BAB',
  },
  p3dataHighlight: {
    fontSize: 12,
    fontFamily: 'RobotoCondensed',
    backgroundColor: '#5A9BAB',
    color: '#FFFFFF',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  p3data: {
    fontSize: 12,
    fontFamily: 'RobotoCondensed',
    color: '#707070',
    textAlign: 'center',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    '&:last-child': {
      paddingRight: 8,
    },
  },

  titleBlock: {
    flexDirection: 'row',
    borderBottom: 2,
    borderBottomColor: '#5A9BAB',
  },
  p3Tabletitle: {
    fontSize: 13,
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#707070',
    marginBottom: 13,
    marginTop: 13,
  },
  tableTreats: {
    width: 70,
    marginRight: 27,
  },
  tableTipCost: {
    width: 51,
    marginRight: 38,
  },
  tableLoan: {
    width: 77,
    marginRight: 45,
  },
  tableSales: {
    width: 41,
    marginRight: 49,
  },
  tableProfit: {
    width: 41,
    marginRight: 27,
  },
  tableBreakEven: {
    width: 61,
  },
  p3TitleGrid: {
    fontFamily: 'RobotoCondensed',
    fontSize: 12,
    color: '#5A9BAB',
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingBottom: 8,
  },
  p3SalesRep: {
    color: '#FFFFFF',
    flexDirection: 'column',
    paddingLeft: 31,
    width: '69%',
  },
  repheader: {
    fontFamily: 'RobotoCondensed',
    fontWeight: 'bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  repName: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 18,
    textTransform: 'uppercase',
  },
  repEmail: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 11,
  },
  repPhone: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 11,
  },
  p3companySection: {
    color: '#FFFFFF',
    flexDirection: 'column',
  },
  p3SoltaLogo: {
    width: 127,
    height: 54,
    marginBottom: 10,
  },
  p3CompanyAddress: {
    fontFamily: 'Roboto',
    fontWeight: 'light',
    fontSize: 11,
  },
  horizontal: {
    flexDirection: 'row', // Display items in a row (horizontal)
  },
  verticle: {
    flexDirection: 'column',
  },
});

// Create Document Component
const ROIpdf = ({ data, user, chartData, chartXData, totalAnnualRevenue, totalAnnualTreatmentCosts, totalAnnualProfit, taxSaving, taxDeduction, tipsToBreakEven, timeRangePracticeRevenue, timeRangeTreatmentCosts, timeRangeProfit, tableBreakdown, roiTipCount, currentTierBreakEven, newxtTierBreakEven, selectedTier, selectedTips, selectedTimeRange, selectedTimeBreakdown }) => {
  //console.log("Inc Data in pdf creation -> ",data);
  //console.log("Inc user in pdf creation -> ",user);
  //console.log("Inc chartData in pdf creation -> ",chartData);
  //console.log("Inc chartXData in pdf creation -> ",chartXData);
  //console.log("Inc roiTipCount in pdf creation -> ",roiTipCount);
  //console.log("Inc currentTierBreakEven in pdf creation -> ",currentTierBreakEven);
  //console.log("Inc newxtTierBreakEven in pdf creation -> ",newxtTierBreakEven);
  //console.log("Inc selectedTier in pdf creation -> ",selectedTier);
  //console.log("Inc selectedTips in pdf creation -> ",selectedTips);
  //Font.register({ family: 'FamilyName', src: source, fontStyle: 'normal', fontWeight: 'normal', fonts?: [] });

  Font.register({
    family: 'RobotoCondensed',
    fonts: [
      { src: RobotoCondensed, fontWeight: 'normal' },
      { src: RobotoCondensedBold, fontWeight: 'bold' },
      { src: RobotoCondensedLight, fontWeight: 'light' },
    ],
  });

  Font.register({
    family: 'Roboto',
    fonts: [
      { src: RobotoLight, fontWeight: 'light' },
      { src: RobotoBold, fontWeight: 'normal' },
    ],
  });

  //console.log('this is my selected tips ---->', selectedTips);

  return (
    <Document>
      {/* PAGE 1 */}
      <Page size='LETTER' style={[styles.page, { backgroundColor: '#fff' }]}>
        <View style={[styles.header, { backgroundColor: '#fff' }]}>
          <View style={[styles.headerTextholder, { color: '#707070' }]}>
            <Text style={[styles.headerText, { color: '#707070' }]}>SOLTA ROI CALCULATOR REPORT</Text>
          </View>
          <View style={styles.logo}>
            {Object.keys(data.systems).map((key) => (
              <Image key={key} src={data.systems[key].logoURL} style={styles.image} />
            ))}
          </View>
        </View>
        <View style={[styles.headerBar, { backgroundColor: '#5A9BAB' }]}>
          <Text>&nbsp;</Text>
        </View>
        {/* Top info section */}
        <View style={styles.clinicInfoBlock}>
          <View style={styles.clinicInfoTopRow}>
            <View style={styles.sectionOne}>
              <Text style={styles.headline}>CLINIC:</Text>
              <Text style={[styles.clinicInfo, { color: '#707070' }]}>{data.clinicName}</Text>
            </View>

            <View style={styles.sectionTwo}>
              <View style={styles.horizontal}>
                <Text style={styles.headline}>SYSTEMS:</Text>
              </View>
              <View>
                {Object.keys(data.systems).map((key) => (
                  <View key={key} style={styles.systemPriceHolder}>
                    <Image src={data.systems[key].logoURL} style={styles.image} />
                  </View>
                ))}
              </View>
            </View>

            <View style={styles.sectionTwo2}>
              <View style={styles.horizontal}>
                <Text style={styles.headline}>SYSTEM SELLING PRICE:</Text>
              </View>
              <View>
                {Object.keys(data.systems).map((key) => (
                  <View key={key} style={styles.systemPriceHolder}>
                    <Text style={styles.priceText}>
                      {'$'}
                      {MakeMoneyValue({ value: data.systems[key].price })}
                    </Text>
                  </View>
                ))}
              </View>
            </View>

            <View style={styles.sectionThree}>
              <Text style={styles.headline}>PURE TIER:</Text>
              <View style={styles.horizontal}>
                {tierLogo(selectedTier)} {/* tier logo */}
                <Text style={[styles.tierInfo, { color: '#707070' }]}>{tierName(selectedTier)}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.treatmentsTableSection}>
          <View style={styles.treatTitle}>
            <Text style={styles.uppercase}>Treamtents</Text>
          </View>

          {/* tableheaders */}
          <View style={styles.profitTableTopSection}>
            {/* Set up list titles */}
            {/* Treatment Tip */}
            <View style={styles.profitTableTopTreatment}>
              <Text style={styles.uppercase}>TREATMENT TIP</Text>
            </View>

            {/* treatments Per Week  /month */}
            <View style={styles.profitTableTopTreatPerMonth}>
              <Text style={styles.uppercase}>{selectedTimeRange === TIMERANGE.weekly ? 'TREATMENTS PER WEEK' : 'TREATMENTS PER MONTH'}</Text>
            </View>

            {/* TREATMENT PRICE TO PATIENT */}
            <View style={styles.profitTableTopPatientPrice}>
              <Text style={styles.uppercase}>TREATMENT PRICE TO PATIENT</Text>
            </View>
            {/* CONSUMABLE PRICE */}
            <View style={styles.profitTableTopConsumable}>
              <Text style={styles.uppercase}>CONSUMABLE PRICE</Text>
            </View>
            {/* OTHER CHARGES */}
            <View style={styles.profitTableTopOther}>
              <Text style={styles.uppercase}>OTHER CHARGES</Text>
            </View>
            {/* PROFIT /WEEK */}
            {/* <View style={styles.profitTableTopWeekProfit}>
                <Text style={styles.uppercase}>PROFIT /WEEK</Text>
              </View> */}
          </View>
          <View style={[styles.tableheaderBar, { backgroundColor: '#5A9BAB', opacity: 1 }]}>
            <Text>&nbsp;</Text>
          </View>

          {/* map if data.tips */}
          {Object.keys(selectedTips).map((p) => (selectedTips[p].id !== '' ? <ProfitBlock row={selectedTips[p]} i={p} key={p} tier={selectedTier} /> : null))}

          <View style={[styles.rowBottom, { backgroundColor: '#5A9BAB', opacity: 1 }]}>
            <Text>&nbsp;</Text>
          </View>
        </View>

        {/* teal amounts section */}
        <View style={styles.totalsSection}>
          <View style={[styles.totalsRow, { marginBottom: 37 }]}>
            <View style={styles.totalsOne}>
              <Text style={styles.totalsTitleOne}>TOTAL ANNUAL REVENUE</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(totalAnnualRevenue())}</Text>
            </View>

            <View style={styles.totalsTwo}>
              <Text style={styles.totalsTitleTwo}>ANNUAL TREATMENT COSTS</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(totalAnnualTreatmentCosts())}</Text>
            </View>

            <View style={styles.totalsThree}>
              <Text style={styles.totalsTitleThree}>TOTAL ANNUAL PROFIT</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(totalAnnualProfit())}</Text>
            </View>
          </View>

          <View style={styles.totalsRow}>
            <View style={styles.totalsOne}>
              <Text style={styles.totalsTitleOneSecond}>TAX SAVINGS FROM SECTION 179 DEDUCTION</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(taxSaving())}</Text>
            </View>

            <View style={styles.totalsTwo}>
              <Text style={styles.totalsTitleTwo}>SYSTEM COSTS AFTER 179 DEDUCTION</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(taxDeduction())}</Text>
            </View>

            <View style={styles.totalsThree}>
              <Text style={styles.totalsTitleThree}># TREATMENTS TO BREAK-EVEN</Text>
              <Text style={styles.totalsTotals}>{convertToRoundedNumber(tipsToBreakEven())}</Text>
            </View>
          </View>
        </View>

        <View style={styles.footerSection}>
          <View style={styles.fullFooter}>
            <Text style={styles.footerText}>
              This is not a contractual document. This document is for information purpose only and does not constitute an offer or create any contractual relationship. We make every reasonable effort to ensure the accuracy and validity of the information provided. However, as policies, price points, dates, conditions, and information are continually changing, we reserve the
              right to make changes at any time and accept no liability or responsibility for any errors or omissions.
            </Text>
          </View>
          <View style={styles.fullFooter}>
            <View style={styles.footLeft}>
              <Text style={styles.footerText}>Solta Medical is a global leader in the aesthetic industry providing innovative products such as Liposonix®, VASERlipo®, VASER® ultrasonic systems, Thermage® radio frequency and Isolaz® acne therapy systems, and the Clear + Brilliant®, Clear + Brilliant Touch® and Fraxel® lasers.</Text>
            </View>
            <View style={styles.footRight}>
              <Text style={styles.footerText}>®/TM are trademarks of Bausch Health Companies Inc. or its affiliates. ©{moment().format('YYYY')} Bausch Health Companies Inc. SAL.XXX.USD23</Text>
            </View>
          </View>
        </View>
      </Page>

      {/* PAGE 2 */}
      <Page size='LETTER' style={[styles.page, { backgroundColor: '#fff' }]}>
        <View style={[styles.header, { backgroundColor: '#fff' }]}>
          <View style={[styles.headerTextholder, { color: '#707070' }]}>
            <Text style={[styles.headerText, { color: '#707070' }]}>SOLTA ROI CALCULATOR REPORT</Text>
          </View>
          <View style={styles.logo}>
            {Object.keys(data.systems).map((key) => (
              <Image key={key} src={data.systems[key].logoURL} style={styles.image} />
            ))}
          </View>
        </View>
        <View style={[styles.headerBar, { backgroundColor: '#5A9BAB' }]}>
          <Text>&nbsp;</Text>
        </View>

        {/* Graph section */}
        <View style={styles.page2Graph}>
          {/* title */}
          <View style={styles.page2header}>
            <Text style={styles.page2subtitle}>
              CUMULATIVE PROFIT BASED ON {roiTipCount} TREATMENT PER {selectedTimeRange === TIMERANGE.weekly ? 'WEEK' : 'MONTH'}
            </Text>
          </View>
          <View style={styles.page2subBar}>
            <View style={styles.horizontal}>
              {/* weekly/monthly with bar under */}
              <View style={styles.verticle}>
                <Text style={styles.weekly}>{selectedTimeBreakdown}</Text>
              </View>
              {/* TierLOGO    Tier Name */}
              <View style={styles.page2subTier}>
                <View style={styles.p2LogoHolder}>
                  {tierLogo(selectedTier)} {/* tier logo */}
                </View>
                <Text style={[styles.tierInfo, { color: '#707070' }]}>{tierName(selectedTier)}</Text>
              </View>
            </View>
          </View>
          {/* graph section */}
          <View style={styles.graphBlock}>
            <View style={styles.breakWeeksHolder}>
              <View style={styles.tierBreak}>
                <Text style={[styles.currentTierWeeks, { color: TierColor(currentTierBreakEven.tier) }]}>{convertToRoundedNumber(currentTierBreakEven.breakEven)}</Text>
                <Text style={styles.breaktext}># {selectedTimeBreakdown === TIMERANGE.weekly ? 'WEEKS' : selectedTimeBreakdown === TIMERANGE.monthly ? 'MONTHS' : selectedTimeBreakdown === TIMERANGE.quarterly ? 'QUARTERS' : selectedTimeBreakdown === TIMERANGE.annual ? 'YEARS' : ''} TO BREAK EVEN AT</Text>
                <Text style={[styles.breakTier, { color: TierColor(currentTierBreakEven.tier) }]}>{tierName(selectedTier)}</Text>
              </View>
              <View style={styles.tierBreak}>
                <Text style={[styles.currentTierWeeks, { color: TierColor(newxtTierBreakEven.tier) }]}>{convertToRoundedNumber(newxtTierBreakEven.breakEven)}</Text>
                <Text style={styles.breaktext}># {selectedTimeBreakdown === TIMERANGE.weekly ? 'WEEKS' : selectedTimeBreakdown === TIMERANGE.monthly ? 'MONTHS' : selectedTimeBreakdown === TIMERANGE.quarterly ? 'QUARTERS' : selectedTimeBreakdown === TIMERANGE.annual ? 'YEARS' : ''} TO BREAK EVEN AT</Text>
                <Text style={[styles.breakTier, { color: TierColor(newxtTierBreakEven.tier) }]}>{newxtTierBreakEven.tier}</Text>
              </View>
            </View>

            {/* GRAPH */}
            <View tyle={styles.graphHolder}>
              <CreateBarChart chartData={chartData} chartXData={chartXData} />
            </View>
          </View>
        </View>

        {/* teal amounts section */}
        <View style={styles.p2TotalsSection}>
          <View style={[styles.totalsRow, { marginBottom: 37 }]}>
            <View style={styles.p2totalsOne}>
              <Text style={styles.totalsTitleOne}>{selectedTimeBreakdown} PRACTICE REVENUE</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(timeRangePracticeRevenue())}</Text>
            </View>

            <View style={styles.totalsTwo}>
              <Text style={styles.totalsTitleTwo}>TOTAL {selectedTimeBreakdown} TREATMENT COSTS</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(timeRangeTreatmentCosts())}</Text>
            </View>

            <View style={styles.totalsThree}>
              <Text style={styles.totalsTitleThree}>TOTAL {selectedTimeBreakdown} PROFIT</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(timeRangeProfit())}</Text>
            </View>
          </View>
        </View>

        {/* FOOTER */}
        <View style={styles.footerSection}>
          <View style={styles.fullFooter}>
            <Text style={styles.footerText}>
              This is not a contractual document. This document is for information purpose only and does not constitute an offer or create any contractual relationship. We make every reasonable effort to ensure the accuracy and validity of the information provided. However, as policies, price points, dates, conditions, and information are continually changing, we reserve the
              right to make changes at any time and accept no liability or responsibility for any errors or omissions.
            </Text>
          </View>
          <View style={styles.fullFooter}>
            <View style={styles.footLeft}>
              <Text style={styles.footerText}>Solta Medical is a global leader in the aesthetic industry providing innovative products such as Liposonix®, VASERlipo®, VASER® ultrasonic systems, Thermage® radio frequency and Isolaz® acne therapy systems, and the Clear + Brilliant®, Clear + Brilliant Touch® and Fraxel® lasers.</Text>
            </View>
            <View style={styles.footRight}>
              <Text style={styles.footerText}>®/TM are trademarks of Bausch Health Companies Inc. or its affiliates. ©{moment().format('YYYY')} Bausch Health Companies Inc. SAL.XXX.USD23</Text>
            </View>
          </View>
        </View>
      </Page>

      {/* PAGE 3 */}
      <Page size='LETTER' style={[styles.page, { backgroundColor: '#fff' }]}>
        <View style={[styles.header, { backgroundColor: '#fff' }]}>
          <View style={[styles.headerTextholder, { color: '#707070' }]}>
            <Text style={[styles.headerText, { color: '#707070' }]}>SOLTA ROI CALCULATOR REPORT</Text>
          </View>
          <View style={styles.logo}>
            {Object.keys(data.systems).map((key) => (
              <Image key={key} src={data.systems[key].logoURL} style={styles.image} />
            ))}
          </View>
        </View>
        <View style={[styles.headerBar, { backgroundColor: '#5A9BAB' }]}>
          <Text>&nbsp;</Text>
        </View>

        {/* table of values section */}
        <View style={styles.tableDataContainer}>
          <View>
            <Text style={styles.p3Tabletitle}>Impact of Multiple Treatments Per {selectedTimeRange === TIMERANGE.weekly ? 'week' : 'month'} on Profitability</Text>
          </View>
          <View style={styles.titleBlock}>
            <View style={styles.tableTreats}>
              <Text style={styles.p3TitleGrid}>Treatments/ {selectedTimeRange === TIMERANGE.weekly ? 'Week' : 'Month'}</Text>
            </View>
            <View style={styles.tableTipCost}>
              <Text style={styles.p3TitleGrid}>{selectedTimeRange} Tip Costs</Text>
            </View>
            <View style={styles.tableLoan}>
              <Text style={styles.p3TitleGrid}>{selectedTimeRange} Loan Payment</Text>
            </View>
            <View style={styles.tableSales}>
              <Text style={styles.p3TitleGrid}>{selectedTimeRange} Sales</Text>
            </View>
            <View style={styles.tableProfit}>
              <Text style={styles.p3TitleGrid}>{selectedTimeRange} Profit</Text>
            </View>
            <View style={styles.tableBreakEven}>
              <Text style={styles.p3TitleGrid}>{selectedTimeRange === TIMERANGE.weekly ? 'weeks' : 'months'} to Break-Even </Text>
            </View>
          </View>

          <View style={styles.p3dataTable}>
            {Object.keys(tableBreakdown).map((p) => (
              <DataTableBlock row={tableBreakdown[p]} roiTipCount={roiTipCount} i={p} key={p} />
            ))}
          </View>
        </View>

        {/* teal sales rep section */}
        <View style={styles.totalsSection}>
          <View style={styles.totalsRow}>
            <View style={styles.p3SalesRep}>
              <Text style={styles.repheader}>SALES REP:</Text>
              <Text style={styles.repName}>{user.fullName}</Text>
              <Text style={styles.repEmail}>{user.email}</Text>
              <Text style={styles.repPhone}>{user.phone}</Text>
            </View>
            <View style={styles.p3companySection}>
              <View>
                <Image style={styles.p3SoltaLogo} src={SoltaLogo} />
              </View>
              <Text style={styles.p3CompanyAddress}>11720 North Creek</Text>
              <Text style={styles.p3CompanyAddress}>Parkway North Suite 100 | </Text>
              <Text style={styles.p3CompanyAddress}>Bothell, WA 98011</Text>
            </View>
          </View>
        </View>

        {/* FOOTER */}
        <View style={styles.footerSection}>
          <View style={styles.fullFooter}>
            <Text style={styles.footerText}>
              This is not a contractual document. This document is for information purpose only and does not constitute an offer or create any contractual relationship. We make every reasonable effort to ensure the accuracy and validity of the information provided. However, as policies, price points, dates, conditions, and information are continually changing, we reserve the
              right to make changes at any time and accept no liability or responsibility for any errors or omissions.
            </Text>
          </View>
          <View style={styles.fullFooter}>
            <View style={styles.footLeft}>
              <Text style={styles.footerText}>Solta Medical is a global leader in the aesthetic industry providing innovative products such as Liposonix®, VASERlipo®, VASER® ultrasonic systems, Thermage® radio frequency and Isolaz® acne therapy systems, and the Clear + Brilliant®, Clear + Brilliant Touch® and Fraxel® lasers.</Text>
            </View>
            <View style={styles.footRight}>
              <Text style={styles.footerText}>®/TM are trademarks of Bausch Health Companies Inc. or its affiliates. ©{moment().format('YYYY')} Bausch Health Companies Inc. SAL.XXX.USD23</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const tierName = (id) => {
  //we have id, we need name

  switch (id) {
    case '"EnHRIMZGhhJZeTCUL6NX"':
      return 'Bronze';
    case '3IaLvKVToLsbcC8neru7':
      return 'Silver';
    case 'hfgJtLRuei3Ac42Gu1tj':
      return 'Gold';
    case 'TktE8KkUfRptydPNLOk1':
      return 'Platinum';
    case 'eEH4ihpXS9hqi1Bd3Q93':
      return 'Emerald';
    case '3vYBprZ763vNRowuvVRI':
      return 'Ruby';
    case '7FiOjjal0x2LkVmv9gbv':
      return 'Diamond';
    default:
      return 'Bronze';
  }
};

const tierLogo = (id) => {
  switch (id) {
    case '"EnHRIMZGhhJZeTCUL6NX"':
      return <Image src={BronzeLogo} alt='Bronze' style={styles.tierLogoImg} />;
    case '3IaLvKVToLsbcC8neru7':
      return <Image src={SilverLogo} alt='Silver' style={styles.tierLogoImg} />;
    case 'hfgJtLRuei3Ac42Gu1tj':
      return <Image src={GoldLogo} alt='Gold' style={styles.tierLogoImg} />;
    case 'TktE8KkUfRptydPNLOk1':
      return <Image src={PlatinumLogo} alt='Platinum' style={styles.tierLogoImg} />;
    case 'eEH4ihpXS9hqi1Bd3Q93':
      return <Image src={EmeraldLogo} alt='Emerald' style={styles.tierLogoImg} />;
    case '3vYBprZ763vNRowuvVRI':
      return <Image src={RubyLogo} alt='Ruby' style={styles.tierLogoImg} />;
    case '7FiOjjal0x2LkVmv9gbv':
      return <Image src={DiamondLogo} alt='Diamond' style={styles.tierLogoImg} />;
    default:
      return <Image src={BronzeLogo} alt='Bronze' style={styles.tierLogoImg} />;
  }
};

const ProfitBlock = ({ row, i, tier }) => {
  //console.log('In profit block, passed in row->', row.id);
  // each rowdata map sent in here for formatting and return of a view -> line in the "table"
  return (
    <View key={i} style={styles.profitRow}>
      <View style={styles.profitRowTreatment}>
        {/* Treatment Tip */}
        <Text style={styles.itemDescription}>{row.sku}</Text>
      </View>
      {/* treatments Per Week  /month */}
      <View style={styles.profitRowTreatPerMonth}>
        <Text style={styles.itemDescription}>{row.amount}</Text>
      </View>
      {/* TREATMENT PRICE TO PATIENT */}
      <View style={styles.profitRowPatientPrice}>
        <Text style={styles.itemDescription}>
          {'$'}
          {MakeMoneyValue({ value: row.treatmentPrice })}
        </Text>
      </View>
      {/* CONSUMABLE PRICE */}
      <View style={styles.profitRowConsumable}>
        <Text style={styles.itemDescription}>
          {'$'}
          {MakeMoneyValue({ value: row[tier] / row.tipsPerPack })}
        </Text>
      </View>
      {/* OTHER CHARGES */}
      <View style={styles.profitRowOther}>
        <Text style={styles.itemDescription}>
          {'$'}
          {row.other ? MakeMoneyValue({ value: row.other }) : 0}
        </Text>
      </View>
      {/* PROFIT /WEEK */}
      {/* <View style={styles.profitRowWeekProfit}>
          <Text style={styles.itemDescription}>{'$'}{MakeMoneyValue({ value: ((row.treatmentPrice - row[tier] + row.other)* row.amount)})}</Text>
        </View> */}
    </View>
  );
};

const DataTableBlock = ({ row, roiTipCount, i }) => {
  //console.log('In DataTable block, passed in row->', row);
  if (row.treatments !== 0) {
    return (
      <View key={i} style={[styles.p3Tablerow, i % 2 === 0 ? styles.evenRow : null, roiTipCount === row.treatments ? styles.highlight : null]}>
        <View style={styles.tableTreats}>
          <Text style={roiTipCount === row.treatments ? styles.p3dataHighlight : styles.p3data}>{row.treatments}</Text>
        </View>
        <View style={styles.tableTipCost}>
          <Text style={roiTipCount === row.treatments ? styles.p3dataHighlight : styles.p3data}>${convertToCurrency(row.tipcost)}</Text>
        </View>
        <View style={styles.tableLoan}>
          <Text style={roiTipCount === row.treatments ? styles.p3dataHighlight : styles.p3data}>${convertToCurrency(row.loanPayment)}</Text>
        </View>
        <View style={styles.tableSales}>
          <Text style={roiTipCount === row.treatments ? styles.p3dataHighlight : styles.p3data}>${convertToCurrency(row.sales)}</Text>
        </View>
        <View style={styles.tableProfit}>
          <Text style={roiTipCount === row.treatments ? styles.p3dataHighlight : styles.p3data}>${convertToCurrency(row.profit)}</Text>
        </View>
        <View style={styles.tableBreakEven}>
          <Text style={roiTipCount === row.treatments ? styles.p3dataHighlight : styles.p3data}>{convertToRoundedNumber(row.breakEven)}</Text>
        </View>
      </View>
    );
  } else return null;
};

const CreateBarChart = ({ chartData, chartXData }) => {
  return (
    <ReactPDFChart>
      <BarChart
        width={537}
        height={275}
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          bottom: 5,
        }}
      >
        <XAxis dataKey='name' />
        <Legend />
        {chartXData.map((xAxis) => (
          <Bar isAnimationActive={false} dataKey={xAxis.key} fill={`${TierColor(xAxis.key)}`} key={xAxis.key} />
        ))}
      </BarChart>
    </ReactPDFChart>
  );
};

const MoneyFormater = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
});

const MakeMoneyValue = ({ value }) => {
  return MoneyFormater.format(value);
};

export default ROIpdf;
