export function convertToNumber(str) {
  if (typeof str === 'string') {
    const stringWithoutCommas = str.replace(/,/g, '');
    let number = parseFloat(stringWithoutCommas);
    if (isNaN(number)) {
      return 0;
    }

    return number;
  } else {
    //It is a number no need to conver
    if (isNaN(str)) {
      return 0;
    }
    return str;
  }
}
