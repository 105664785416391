import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export default function LoadingPage() {
  return (
    <div>
      <Paper>
        <Grid container direction='row' justifyContent='center' alignItems='center' item xs={12}>
          <Grid item xs={1} align='center'>
            {/* <MetaStudiosGreenM className={classes.metaLogoAnimation} /> */}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
