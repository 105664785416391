import React from 'react';
import soltaLogo from './images/solta_logo.svg';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles/logoLoadingBlock.module.scss';

export default function LoadingGraphic() {
  return (
    <div className={styles.root}>
      <Grid container direction='row' justifyContent='center' alignItems='center' item xs={12} spacing={2}>
        <Grid item xs={12} align='center'>
          <img src={soltaLogo} className={styles.image} alt='Solta' />
        </Grid>
        <Grid item xs={12} align='center'>
          <CircularProgress color='primary' />
        </Grid>
      </Grid>
    </div>
  );
}
