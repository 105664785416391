export const soltaColor = '#5a9bab';
export const MultiColor = '#5A9BAB';
export const white = '#fff';
export const black = '#000';
export const thermageColor = '#45143F';
export const clearColor = '#077FB2';
export const fraxelColor = '#691E33';
export const isolazColor = '#00AFAA';
export const vaserColor = '#007bff';

//////////////////////////////////
/// Tier colours
//////////////////////////////////
export const list = soltaColor;
export const bronze = '#8E755B';
export const silver = '#909291';
export const gold = '#A87D35';
export const platinum = '#909291';
export const emerald = '#009975';
export const ruby = '#AE1950';
export const diamond = '#E5EBF2';
